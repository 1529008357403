<template>
  <!--
   Jest problem z v-theme-providerem, że bez with-background nie zmienia koloru tekstu.
   Można zastosować obejście, dodając prop with-background i klase 'bg-transperent',
   ale ona ingeruje w kolor tekstu (z jakiegos powodu).

   Dlatego do momentu kiedy albo nie poprawią klasy bg-transparent, albo działa v-theme-providera,
   wprowadzam workaround ze sztywną klasą w tym miejscu.
   -->
  <v-theme-provider
      :theme="contrastTheme"
      :class="{
        'l-contrast-provider': !props.withBackground,
      }"
      :with-background="true"
  >
    <slot v-bind="{isLight, isDark, contrastTheme}"/>
  </v-theme-provider>
</template>
<script lang="ts" setup>

import {useTheme} from 'vuetify';
import type {ThemeType} from '~/enums/ThemeType';

const props = defineProps({
  withBackground: {
    required: false,
    type: Boolean,
    default: false,
  },
  disable: {
    type: Boolean,
    default: false,
  },
});

const {name,} = useTheme();

const isDark = computed<boolean>(() => name.value === 'dark');
const isLight = computed<boolean>(() => name.value === 'light');

const contrastTheme = computed<ThemeType>(() => isLight.value ? 'dark' : 'light');
</script>
<style scoped lang="scss">
.l-contrast-provider {
  background-color: transparent !important;
}
</style>
